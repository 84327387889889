'use client' // Error components must be Client Components

import { useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { useTranslation } from '../i18n/client'
export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  const { t } = useTranslation()
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <div className="  flex w-full justify-center items-center h-full flex-col ">
      <h2>{t('Something went wrong')}!</h2>
      <Button
        onClick={() => {
          window.location.reload()
        }}
      >
        {t('Try again')}
      </Button>
    </div>
  )
}
